// Build information, automatically generated by `ng-info`
const build = {
    version: "0.0.0",
    timestamp: "Sat Dec 28 2024 15:21:16 GMT+0000 (Coordinated Universal Time)",
    message: null,
    git: {
        user: null,
        branch: "staging",
        hash: "f865e8",
        fullHash: "f865e8fc39d1a0cec0e6f2baab98f252ed83acb4"
    }
};

export default build;